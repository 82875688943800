.homepage-3 {

    /* BANNER */
    .banner {
        padding-top: 40px;

        h1 {
            text-align: center;
            font-size: 50px;
            font-weight: 800;
            color: #000;
            margin-bottom: 0px;
        }

        .banner-image {
            margin-top: 30px;

            img {
                width: 100%;
                height: 600px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }

    /* LISTING SEARCH TABS */
    .banner-tabs {
        margin-top: -60px;

        .tabs-details {
            background-color: #FFF;
            border: 1px solid #E5E5E5;
            border-bottom: 0px solid transparent;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .nav-item {
                a {
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                    padding: 10px 20px;
                }

                a.active {
                    background-color: #FFF;
                    border-radius: 0px;
                    border-bottom: 2px solid #000;
                }
            }
        }

        .bg-white {
            padding: 15px 20px;
            box-shadow: 0px 4px 4px 0px rgba($color: #000000, $alpha: 0.15);
            border-radius: 5px;

            h5 {
                font-size: 15px;
                font-weight: 600;
                color: #323232;
                margin-bottom: 10px;
            }

            .listing-search {
                background-color: #FFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                gap: 15px;
        
                .banner-control {
                    width: 100%;
                    background-color: #FFF;
                    border: 1px solid #E5E5E5;
                    padding: 10px 15px;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 500;
                }
        
                .banner-control::placeholder {
                    color: #6F6F6F;
                    font-size: 14px;
                }
        
                .search-button {
                    white-space: nowrap;
                    background-color: #BE8F00;
                    border-color: #BE8F00;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 8px 16px;
                }
            }
        }
    }

    /* ACTIVE LISTINGS */
    .active-listings {
        padding: 70px 0px;
        margin: 0px 30px;

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }

            .listings-link {
                display: block;
                text-decoration: none;
                color: #000;
                font-size: 18px;
                font-weight: 600;
            }

            .listings-link:hover {
                color: #BE8F00;
            }
        }

        .property-box {
            position: relative;

            img {
                width: 100%;
                height: 350px;
                object-fit: cover;
                border-radius: 5px;
            }

            .bg-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: #000000, $alpha: 0.3);
                border-radius: 5px;
                padding: 15px;
                display: flex;
                align-items: flex-end;

                .info-box {
                    width: 100%;
                    background-color: #FFF;
                    border-radius: 5px;
                    padding: 15px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .street-loading {
                        width: 150px;
                        height: 20px;
                    }

                    .city-loading {
                        width: 100px;
                        height: 15px;
                        margin-top: 10px;
                    }

                    h5 {
                        font-size: 14px;
                        font-weight: 600;
                        color: #000;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 13px;
                        color: #6F6F6F;
                        font-weight: 400;
                        margin-bottom: 0px;
                    }

                    .price {
                        font-size: 14px;
                        font-weight: 700;
                        margin-bottom: 0px;
                        color: #000;
                    }
                }
            }
        }
    }

    /* ABOUT AND BLOGS */
    .about-and-blogs {
        background: url('../../assets/about-bg.webp');
        background-repeat: no-repeat;
        background-size: cover;

        .bg-overlay {
            background-color: rgba($color: #000000, $alpha: 0.7);
            padding: 50px 0px;

            img {
                width: 100%;
                height: 450px;
                object-fit: cover;
                border-radius: 10px;
            }

            .heading {

                h2 {
                    font-size: 35px;
                    font-weight: 800;
                    color: #FFF;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 18px;
                    font-weight: 500;
                    margin-top: 20px;
                    margin-bottom: 0px;
                    color: #FFF;
                }

                .learn-more {
                    background-color: transparent;
                    font-size: 14px;
                    font-weight: 600;
                    margin-top: 25px;
                    border-color: transparent;
                    padding: 8px 16px;
                    color: #FFF;
                    padding: 0px;
                    text-decoration: underline;
                }

                .learn-more:hover {
                    color: #BE8F00;
                }

            }

            .blog-box {
                height: 100%;
                box-shadow: 0px 2px 20px 0px rgba($color: #000000, $alpha: 0.15);
                border: 0px solid transparent;

                .blog-box-body {
                    padding: 0px;

                    img {
                        width: 100%;
                        height: 200px;
                        object-fit: cover;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }

                    .details {
                        padding: 20px;
                        padding-bottom: 0px;

                        h5 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 0px;
                            color: #323232;
                        }

                        .desc {
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: 0px;
                            margin-top: 5px;
                            font-weight: 400;
                            color: #6F6F6F;
                        }
                    }
                }

                .blog-box-footer {
                    background-color: #FFF;
                    border-top: 0px solid transparent;
                    padding-bottom: 15px;
                    padding-top: 15px;

                    .date {
                        font-size: 14px;
                        color: #6F6F6F;
                        font-weight: 500;
                        margin-bottom: 0px;
                    }
                }
            }

            .blog-box:hover {
                .blog-box-body {
                    .details {
                        h5 {
                            color: #BE8F00;
                        }
                    }
                }
            }
        }
    }

    /* TESTIMONIALS */
    .testimonials {
        padding-top: 70px;

        .heading {
            h2 {
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }

            h5 {
                font-size: 18px;
                margin-top: 10px;
                font-weight: 500;
                color: #6F6F6F;
                margin-bottom: 0px;
            }
        }

        .testimonial-box {
            border: 1px solid #E5E5E5;
            border-radius: 10px;
            padding: 25px;
            width: 100%;

            h5 {
                font-size: 16px;
                font-weight: 600;
                color: #000;
                margin-bottom: 0px;
            }

            p {
                font-size: 14px;
                margin-top: 15px;
                font-weight: 400;
                color: #6F6F6F;
                margin-bottom: 0px;
            }

            .testimonial-profile {
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 25px;
                border-top: 1px solid #E5E5E5;
                padding-top: 15px;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                .profile-details {
                    margin-left: 15px;

                    h5 {
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        color: #6F6F6F;
                        margin-top: 0px;
                    }
                }
            }
        }
    }

    /* INSTAGRAM WALL */
    .instagram-wall {
        padding: 70px 0px;

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-size: 35px;
                font-weight: 800;
                color: #000;
                margin-bottom: 0px;
            }

            .instagram-link {
                display: block;
                text-decoration: none;
                color: #000;
                font-size: 25px;
                font-weight: 600;
            }

            .instagram-link:hover {
                color: #BE8F00;
            }
        }

        .instagram-box {
            border-radius: 5px;
            position: relative;
            transition: opacity 0.3s ease-in-out;

            video {
                width: 100%;
                height: 400px;
                object-fit: cover;
                border-radius: 5px;
            }

            img {
                width: 100%;
                height: 400px;
                object-fit: cover;
                border-radius: 5px;
            }

            .bg-overlay {
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba($color: #000000, $alpha: 0.3);
                width: 100%;
                height: 400px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
            }
        }

        .instagram-box:hover {
            .bg-overlay {
                opacity: 1;
            }
        }
    }

    /* CALL TO ACTION */
    .call-to-action {
        padding: 70px 0px;
        background-color: #000;
        text-align: center;
        background: url('../../assets/cta-banner.webp');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        h5 {
            text-align: center;
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 0px;
            color: #FFF;
        }

        h1 {
            margin-top: 40px;
            font-weight: 800;
            font-size: 50px;
            color: #FFF;
            text-align: center;
        }

        .explore-btn {
            background-color: #000;
            border: 2px solid #FFF;
            font-size: 16px;
            font-weight: 600;
            padding: 8px 16px;
            margin-top: 60px;
        }

        .explore-btn:hover {
            background-color: #FFF;
            color: #000;
        }
    }
}

.logo-box {
    .company-logo {
        width: 120px;
        height: auto;
    }
}

.main-menu {
    .menu-link {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .call-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #323232;
        margin-top: 10px;

        p {
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 600;
            margin-left: 5px;
        }
    }

    .call-link:hover {
        color: #BE8F00;

        svg {
            fill: #BE8F00;
        }
    }
}