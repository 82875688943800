@media(max-width: 575px) {
    .header-3-mobile {
        display: block !important;
    }

    .header-3-desktop {
        display: none !important;
    }

    .homepage-3 .call-to-action {
        padding: 60px 0px !important;
        background: none !important;
        background-color: #000 !important;
    }

    .homepage-3 .call-to-action h5 {
        font-size: 18px !important;
    }

    .homepage-3 .call-to-action h1 {
        font-size: 30px !important;
    }

    .homepage-3 .call-to-action .explore-btn {
        margin-top: 30px !important;
    }

    .homepage-3 .instagram-wall .heading {
        display: block !important;
    }

    .homepage-3 .instagram-wall .heading h2 {
        font-size: 25px !important;
    }

    .homepage-3 .instagram-wall .heading .instagram-link {
        font-size: 20px !important;
    }

    .homepage-3 .testimonials .testimonial-box {
        margin-top: 15px !important;
    }

    .homepage-3 .testimonials .heading h2 {
        font-size: 25px !important;
    }

    .homepage-3 .about-and-blogs .bg-overlay .blog-box {
        height: auto !important;
        margin-top: 15px !important;
    }

    .homepage-3 .about-and-blogs .bg-overlay .heading h2 {
        font-size: 25px !important;
        margin-top: 15px;
    }

    .homepage-3 .about-and-blogs .bg-overlay .heading p {
        font-size: 15px !important;
    }

    .homepage-3 .about-and-blogs .bg-overlay .heading .learn-more {
        font-size: 15px !important;
    }

    .homepage-3 .active-listings {
        margin: 0px !important;
        padding: 30px 0px !important;
    }

    .homepage-3 .active-listings .heading {
        display: block !important;
    }

    .homepage-3 .active-listings .heading h2 {
        font-size: 25px !important;
    }

    .homepage-3 .banner-tabs .listing-search {
        display: block !important;
    }

    .homepage-3 .banner-tabs .listing-search .search-button {
        width: 100% !important;
        margin-top: 10px !important;
    }

    .homepage-3 .banner .banner-image {
        margin: 0px 15px !important;
    }

    .homepage-3 .banner .banner-image img {
        height: 300px !important;
    }

    .homepage-3 .banner h1 {
        font-size: 35px !important;
        margin-bottom: 15px !important;
    }

    .homepage-3 .banner-tabs {
        margin-top: 15px !important;
    }

    .homepage-3 .instagram-wall {
        padding: 30px 0px !important;
    }

    .footer-3 .footer-main .agent-details {
        display: block !important;
    }

    .footer-3 .footer-main .agent-details .details-box {
        margin-bottom: 15px;
    }

    .footer-3 .footer-main .agent-name {
        font-size: 20px !important;
    }

    .footer-3 .footer-main .agent-details .details-box h5 {
        font-size: 16px !important;
    }

    .footer-3 .footer-main .agent-details .details-box p {
        font-size: 13px !important;
    }

    .footer-3 .footer-main .agent-details .details-box a {
        font-size: 13px !important;
    }

    .footer-3 .footer-main .footer-small-info {
        font-size: 12px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .footer-3 .copyright-main {
        display: block !important;
    }

    .footer-3 .copyright-main h5 {
        text-align: center !important;
        font-size: 14px !important;
        margin-bottom: 10px !important;
    }

    .footer-3 .copyright-main .links {
        justify-content: center;
    }

    .footer-3 .copyright-main .links a {
        padding: 0px 5px !important;
        font-size: 11px !important;
    }

    .seller-overview .page-banner .overlay-text h1 {
        font-size: 35px !important;
    }

    .seller-overview .page-content .content h2 {
        font-size: 25px !important;
    }

    .buyer-overview .page-banner .overlay-text h1 {
        font-size: 35px !important;
    }

    .buyer-overview .page-content .content h2 {
        font-size: 25px !important;
    }

    .contact-us .page-banner .overlay-text h1 {
        font-size: 35px !important;
    }

    .contact-us .details iframe {
        margin-top: 10px;
        height: 400px !important;
    }

    .active-listings-page-2 .property-listings .section-header .switcher-buttons {
        justify-content: center !important;
        margin-top: 10px;
    }

    .active-listings-page-2 .property-listings .price-filters .react-select.sort-control .custom-select__control {
        margin-top: 10px;
    }

    .active-listings-page-2 .property-listings .price-filters .filters-btn {
        margin-top: 10px;
        height: auto !important;
    }

    .listing-list .listing-list-body .property-images .swiper {
        width: 100px !important;
    }

    .listing-list .listing-list-body .property-images .property-img {
        width: 100px !important;
        height: 100px !important;
    }

    .listing-list .listing-list-body .property-details .top-section {
        display: block !important;
    }

    .listing-list .listing-list-body .property-details .top-section h5 {
        font-size: 16px !important;
        margin-bottom: 5px !important;
    }

    .listing-list .listing-list-body .property-details .top-section .badge {
        padding: 5px 10px !important;
    }

    .listing-list .listing-list-body .property-details .top-section .images-number {
        padding: 2px 10px !important;
    }

    .listing-list .listing-list-body .property-details .middle-section h5 {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .listing-list .listing-list-body .property-details .middle-section p {
        margin-top: 0px !important;
    }

    .listing-list .listing-list-body .property-details .middle-section {
        display: block !important;
    }

    .listing-list .listing-list-body .property-details .middle-section p .mls-no {
        font-size: 14px !important;
    }

    .listing-list .listing-list-body .property-details .bottom-section {
        display: block !important;
        margin-top: 5px !important;
    }

    .listing-list .listing-list-body .property-details .bottom-section .other-details .details-box {
        margin-top: 5px !important;
        margin-left: 0px !important;
        margin-right: 25px !important;
    }

    .active-listings-page-2 .listing-list .listing-list-body .property-details .top-section h2 {
        font-size: 16px !important;
        margin-bottom: 10px !important;
    }

    .active-listings-page-2 .listing-list .listing-list-body .property-details .top-section .badge p {
        font-size: 12px !important;
    }

    .property-details .details-header {
        display: block !important;
    }

    .property-details {
        .details-header {
            display: block !important;

            .address {
                h5 {
                    font-size: 16px !important;
                    text-align: center;
                }

                p {
                    text-align: center;
                }
            }

            .features {
                margin-top: 10px;
                justify-content: center;

                .tile {
                    padding: 0px 10px !important;

                    .value {
                        white-space: nowrap !important;
                        font-size: 14px !important;
                    }

                    .label {
                        font-size: 12px !important;
                    }
                }

                .tile:first-child {
                    padding-left: 0px !important;
                    border-left: 0px solid transparent;
                }
            }
        }

        .page-left {
            .property-photos {
                .img-slider {
                    img {
                        height: 300px !important;
                    }
                }
            }

            .description .content-css {
                font-size: 14px !important;
            }

            .mortgage-calculator {
                form {
                    margin-top: 10px !important;
                }
            }

            .nearby-schools {
                overflow-x: auto;
                overflow-y: hidden;
            }
        }

        .page-right {
            margin-top: 15px;
        }
    }

    .logo-box {
        .company-logo {
            width: 140px !important;
            height: auto !important;
            padding-right: 15px !important;
        }

        .agent-logo {
            width: 120px !important;
            height: auto !important;
            padding-left: 15px !important;
            border-left: 2px solid rgba($color: #000000, $alpha: 0.3);
        }
    }

    .logo-box {
        display: flex;
        align-items: center;

        .company-logo {
            width: 150px;
            height: auto;
        }

        p {
            font-size: 15px;
            font-weight: 600;
            color: #000;
            margin-bottom: 0px;
            padding: 0px 10px;
        }

        .personal-logo {
            width: 50px !important;
            height: 50px !important;
            border: 1px solid #E5E5E5;
            border-radius: 9999px;
        }
    }
}