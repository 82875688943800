.blogs {
    .page-banner {
        background: url('../../../assets/contact-us-banner.webp');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 120px 0px;
        position: relative;

        .overlay-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
                font-size: 50px;
                font-weight: 800;
                color: #FFF;
                margin-bottom: 0px;
                text-align: center;
            }

            h2 {
                font-size: 20px;
                font-weight: 500;
                font-style: italic;
                margin-top: 15px;
                margin-bottom: 0px;
                color: #FFF;
                text-align: center;
            }
        }
    }

    .page-content {
        padding: 60px 0px;

        .blog-card {
            position: relative;
            border: 2px solid #E5E5E5;
            border-radius: 5px;
            padding: 10px;
            transition: 0.3s all ease-in-out;

            img {
                width: 100%;
                height: 250px;
                object-fit: cover;
                border-radius: 5px;
            }

            .details {
                position: absolute;
                bottom: 15px;
                left: 15px;
                width: 90%;
                padding: 10px 15px;
                border-radius: 5px;
                background-color: #FFF;

                .posting-details {
                    h2 {
                        font-size: 13px;
                        color: grey;
                        font-weight: 600;
                        margin-bottom: 0px;
                    }

                    .time-loading {
                        width: 100px;
                        height: 15px;
                    }
                }

                .title {
                    margin-top: 10px;
                    font-size: 15px;
                    font-weight: 700;
                    color: #323232;
                    margin-bottom: 0px;
                }

                .title-loading {
                    height: 35px;
                }
            }
        }

        .blog-card:hover {
            border: 2px solid #BE8F00;

            .title {
                color: #BE8F00;
            }
        }

        .blog-sidebar {
            position: sticky;
            top: 0;
            background-color: #F5F5F5;
            border-radius: 5px;
            padding: 20px;

            h2 {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 0px;
                border-bottom: 2px solid #E5E5E5;
                padding-bottom: 10px;
            }

            .categories-link {
                margin-top: 15px;

                .link-item {
                    display: block;
                    text-decoration: none;
                    font-size: 14px;
                    color: #323232;
                    font-weight: 500;
                    margin-bottom: 10px;
                }

                .link-item:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        .blogs-pagination {
            margin-top: 20px;
            display: flex;
            align-items: center;
            list-style-type: none;
            justify-content: center;

            .previous,
            .next {
                background-color: #fff;
                border-radius: 50%;
                padding: 4px 10px;
                border: 1px solid #e5e5e5;

                a {
                    text-decoration: none;
                    color: #323232;
                }
            }

            .blog-pagination-btn {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                background-color: #FFF;
                border: 1px solid #E5E5E5;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px;
            }

            li {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                border: 1px solid #e5e5e5;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                cursor: pointer;
                padding: 5px;

                a {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            li:hover {
                background-color: #BE8F00;

                a {
                    color: #fff;
                }
            }

            .active {
                background-color: #BE8F00;

                a {
                    color: #fff;
                }
            }
        }

        .filters-applied {
            display: flex;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 15px;

            p {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0px;
                margin-right: 10px;
            }

            .applied-filters-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                .applied-filter {
                    display: flex;
                    align-items: center;
                    background-color: rgba($color: #BE8F00, $alpha: 0.2);
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-right: 5px;

                    p {
                        font-weight: 400;
                        font-size: 12px;
                        color: #BE8F00;
                    }

                    .close-btn {
                        min-height: auto;
                        padding: 0px;
                        background-color: transparent;
                        border-color: transparent;
                    }
                }
            }
        }
    }
}

.blog-detail {
    padding: 60px 0px;

    .blog-detail-desc {
        padding-right: 30px;

        .description-inner {
            p {
                span {
                    font-family: 'Figtree', sans-serif !important;
                    font-size: 16px !important;
                    line-height: 24px !important;
                    font-weight: 500 !important;
                    color: #555555 !important;
                }

                a {
                    span {
                        color: #BE8F00 !important;
                    }
                }
            }

            p {
                h1 {
                    span {
                        display: block;
                        font-size: 25px !important;
                        font-weight: 700 !important;
                        color: #BE8F00 !important;
                        margin-bottom: 15px !important;
                        margin-top: 20px !important;
                        line-height: 36px !important;
                    }
                }

                h2 {
                    span {
                        display: block;
                        font-size: 25px !important;
                        font-weight: 700 !important;
                        color: #BE8F00 !important;
                        margin-bottom: 15px !important;
                        margin-top: 20px !important;
                        line-height: 36px !important;
                    }
                }

                h3 {
                    span {
                        display: block;
                        font-size: 22px !important;
                        font-weight: 700 !important;
                        color: #BE8F00 !important;
                        margin-bottom: 15px !important;
                        margin-top: 20px !important;
                        line-height: 36px !important;
                    }
                }

                h4 {
                    span {
                        display: block;
                        font-size: 20px !important;
                        font-weight: 700 !important;
                        color: #BE8F00 !important;
                        margin-bottom: 15px !important;
                        margin-top: 20px !important;
                        line-height: 36px !important;
                    }
                }

                h5 {
                    span {
                        display: block;
                        font-size: 18px !important;
                        font-weight: 700 !important;
                        color: #BE8F00 !important;
                        margin-bottom: 15px !important;
                        margin-top: 20px !important;
                        line-height: 36px !important;
                    }
                }

                h6 {
                    span {
                        display: block;
                        font-size: 16px !important;
                        font-weight: 700 !important;
                        color: #BE8F00 !important;
                        margin-bottom: 15px !important;
                        margin-top: 20px !important;
                        line-height: 36px !important;
                    }
                }
            }
        }

        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #555555;
        }

        h1 {
            font-weight: 800;
            color: #323232;
            font-size: 35px;
            margin-bottom: 20px;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 5px;
        }

        h5 {
            font-size: 30px;
            font-weight: 700;
            color: #BE8F00;
            margin-bottom: 15px;
            margin-top: 20px;
        }

        .content-list {
            margin: 15px 0px;

            li {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #555555;
                margin-bottom: 10px;
            }
        }
    }

    .sidebar {
        position: sticky;
        top: 0;
        padding-top: 15px;

        .connect-with-agent {
            position: relative;
            margin-bottom: 15px;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 5px;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                background-color: rgba($color: #000000, $alpha: 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                h5 {
                    color: #fff;
                    font-size: 20px;
                    margin-bottom: 15px;
                    font-weight: 600;
                }

                .get-started {
                    background-color: #BE8F00;
                    font-size: 14px;
                    font-weight: 600;
                    border-color: #BE8F00;
                    padding: 8px 20px;
                }
            }
        }

        .you-might-like {
            background-color: #f5f5f5;
            border-radius: 5px;
            padding: 20px;

            .box-header {
                border-bottom: 1px solid rgba($color: #323232, $alpha: 0.2);
                padding-bottom: 10px;

                h5 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 0px;
                    color: #323232;
                }
            }

            .blogs-list-view {
                margin-top: 20px;

                .blog-list-card {
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 5px;
                    }

                    h5 {
                        font-size: 16px;
                        font-weight: 600;
                        color: #BE8F00;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        color: #555555;
                        margin-top: 5px;
                        margin-bottom: 0px;
                    }
                }

                .blog-list-card:hover {
                    h5 {
                        text-decoration: underline;
                    }
                }
            }
        }

        .blog-categories {
            background-color: #f5f5f5;
            border-radius: 5px;
            padding: 20px;
            margin-bottom: 15px;

            .box-header {
                border-bottom: 1px solid rgba($color: #323232, $alpha: 0.2);
                padding-bottom: 10px;

                h5 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 0px;
                    color: #323232;
                }
            }

            .box-body {
                padding-top: 15px;

                .category-link {
                    display: block;
                    font-size: 15px;
                    color: #323232;
                    font-weight: 500;
                    text-decoration: none;
                    margin-bottom: 10px;
                }

                .category-link:last-child {
                    margin-bottom: 0px;
                }

                .category-link:hover {
                    color: #BE8F00;
                }
            }
        }
    }
}